import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TreetableModule } from 'ng-material-treetable';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { MapviewComponent } from './mapview/mapview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FullLayoutComponent } from './layouts/full-layout.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MaterialModule } from './material.module';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './login/login.component';
import { AlertsModule } from 'angular-alert-module';
import { HttpClientModule } from '@angular/common/http';
import { CircleComponent } from './circle/circle.component';
import { TrackingdetailsComponent } from './trackingdetails/trackingdetails.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MAT_DIALOG_DATA } from '@angular/material';
import { AddcircleComponent } from './circle/addcircle/addcircle.component';
import { AdduserComponent } from './user/adduser/adduser.component';
import { AddroleComponent } from './user/addrole/addrole.component';
import { AdminAuthGuard } from './guards/admin-auth-gaurd';
import { UserAuthGuard } from './guards/user-auth-gaurd';
import { AgmDirectionModule } from 'agm-direction';
import { TreeTableModule } from 'primeng/treetable';
import { DivisionComponent } from './division/division.component';
import { AdddivisionComponent } from './division/adddivision/adddivision.component';
import { AddsubdivisionComponent } from './subdivision/addsubdivision/addsubdivision.component';
import { SubdivisionComponent } from './subdivision/subdivision.component';
import { RangeComponent } from './range/range.component';
import { AddrangeComponent } from './range/addrange/addrange.component';
import { AddsectionComponent } from './section/addsection/addsection.component';
import { SectionComponent } from './section/section.component';
import { BeatComponent } from './beat/beat.component';
import { AddbeatComponent } from './beat/addbeat/addbeat.component';
import { AddanimalComponent } from './animal/addanimal/addanimal.component';
import { AnimalComponent } from './animal/animal.component';
import { AddtrackerComponent } from './tracker/addtracker/addtracker.component';
import { TrackerComponent } from './tracker/tracker.component';
import { AddanimalnameComponent } from './animal/addanimalname/adddanimalname.component';
import { OrganizationComponent } from './organization/organization.component';
import { AddorganizationComponent } from './organization/addorganization/addorganization.component';
import { AddtrackersComponent } from './trackers/addtrackers/addtrackers.component';
import { TrackersComponent } from './trackers/trackers.component';

const googleMapsCore = AgmCoreModule.forRoot({
  apiKey: 'AIzaSyDtgU-FznLxZQL8kwIB0ByU_WtnNTskwJE',
});
@NgModule({
  declarations: [
    AppComponent,
    MapviewComponent,
    DashboardComponent,
    FullLayoutComponent,
    UserComponent,
    LoginComponent,
    CircleComponent,
    DivisionComponent,
    SubdivisionComponent,
    RangeComponent,
    SectionComponent,
    BeatComponent,
    AnimalComponent,
    OrganizationComponent,
    TrackerComponent,
    TrackersComponent,
    TrackingdetailsComponent,
    AddcircleComponent,
    AdddivisionComponent,
    AddsubdivisionComponent,
    AddrangeComponent,
    AddsectionComponent,
    AddbeatComponent,
    AddanimalComponent,
    AddanimalnameComponent,
    AddorganizationComponent,
    AddtrackerComponent,
    AddtrackersComponent,
    AdduserComponent,
    AddroleComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    googleMapsCore,
    AgmDirectionModule,
    MaterialModule,
    AlertsModule.forRoot(),
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    TreetableModule,
    TreeTableModule
  ],
  entryComponents: [
    AddcircleComponent,
    AdddivisionComponent,
    AddsubdivisionComponent,
    AddrangeComponent,
    AddsectionComponent,
    AddbeatComponent,
    AddanimalComponent,
    AddanimalnameComponent,
    AddorganizationComponent,
    AddtrackerComponent,
    AddtrackersComponent,
    AdduserComponent,
    AddroleComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  },
  { provide: MAT_DIALOG_DATA, useValue: {} },
    AdminAuthGuard,
    UserAuthGuard,
    DatePipe
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }