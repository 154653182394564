import { Component, OnInit } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConstants } from '../AppConstants'; import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrls: ['./mapview.component.css']
})
export class MapviewComponent implements OnInit {
  loader = "false";
  public kmlURL;
  // public trip = { tripName: null, vehicleId: null };
  response = { code: null, responseData: null, message: null };
  tripDetails: string;
  vehicleNumber: string;
  type: string;
  startDate: any;
  endDate: any;
  // dashboardShow: boolean;
  dashboardtrackerId: any;
  trackerList = [];
  loogedUserRole: string;
  loogedUOrganization: string;
  constructor(private titleService: Title, private http: HttpClient, private router: Router, private datepipe: DatePipe, private _snackBar: MatSnackBar) {
    // this.kmlURL = AppConstants.KML_URL;
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    this.getTripDetails();
    this.getTrip();
    this.titleService.setTitle('Gaja Map View');
  }

  getTripDetails() {
    let form = JSON.parse(localStorage.getItem('formdetails'));
    let trackerId = localStorage.getItem('trackerId');
    this.dashboardtrackerId = trackerId;
    // alert(this.dashboardtrackerId)
    let trackingObj = { startDate: form.startDate, endDate: form.endDate, trackerId: trackerId };
    this.http.post(AppConstants.BASE_URL + AppConstants.ALL_TRACKINGS_LIVE_TRACKER, trackingObj)
      .subscribe((data) => {
        this.loader = "false";
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        this.tripDetails = this.response.responseData;
        this.lat = this.response.responseData.lat;
        this.lng = this.response.responseData.lon;
      });
  }

  getTrip() {
    this.loader = "true";
    let trackers = [];
    let trackerId = localStorage.getItem('trackerId');
    let quick = localStorage.getItem('quick');
    // trackers.push(trackerId);
    // alert(quick);
    // alert(quick == 'yes');
    // this.trip.tripName = localStorage.getItem('tripName');
    // this.trip.vehicleId = localStorage.getItem('vehicleId');
    // this.tripDetails = JSON.parse(localStorage.getItem('tripdetails'));
    let form = JSON.parse(localStorage.getItem('formdetails'));
    // console.log(form)
    let trackingObj = { startDate: form.startDate, endDate: form.endDate, trackerId: trackerId };
    this.startDate = form.startDate;
    this.endDate = form.endDate;
    this.dashboardtrackerId = trackerId;
    // localStorage.setItem('startDate', dte.getTime());
    // localStorage.setItem('endDate', dte2.getTime());
    // console.log(quick)

    if (quick.length > 1 && quick == 'yes') {
      this.type = "Quick";
      this.http.post(AppConstants.BASE_URL + AppConstants.QUICK_TRACKINGS_TRACKER, trackingObj)
        .subscribe((data) => {
          this.loader = "false";
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          // if (this.response.responseData.length > 0)
          // for (var i = 0; i < this.response.responseData.length; i++) {
          // this.tripDetails = this.response.responseData[this.response.responseData.length - 1];
          // }
          // alert(this.tripDetails);

          this.showMap2(this.response.responseData)
        });
    } else {
      // console.log(trackingObj);
      this.http.post(AppConstants.BASE_URL + AppConstants.ALL_TRACKINGS_TRACKER, trackingObj)
        .subscribe((data) => {
          this.loader = "false";
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          // console.log(this.response);
          // if (this.response.responseData.length > 0)
          // for (var i = 0; i < this.response.responseData.length; i++) {
          // this.tripDetails = this.response.responseData[0];
          // }
          // alert(this.tripDetails);
          this.showMap(this.response.responseData);
        });
    }
  }

  downloadTripExcel() {
    let trackerId = localStorage.getItem('trackerId');
    let form = JSON.parse(localStorage.getItem('formdetails'));
    // console.log(this.trip)
    let trackingObj = { startDate: form.startDate, endDate: form.endDate, trackerId: trackerId };
    this.http.post(AppConstants.BASE_URL + AppConstants.DOWNLOAD_TRACKINGS_DETAILS, trackingObj)
      .subscribe((data) => {
        this.response = JSON.parse(JSON.stringify(data));

        // var blob = new Blob([this.response.responseData], { type: 'application/vnd.ms-excel' });

        // var downloadURL = window.URL.createObjectURL(blob);
        var downloadURL = AppConstants.DWNLD_URL + this.response.message;
        // var downloadURL = 'http://103.70.144.137:8080/image/Allzone.kml';
        // alert(downloadURL);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.response.message;
        link.click();
      });
  }

  getDate = function (num) {
    var d = new Date(num);
    return d.toLocaleDateString() // 7/25/2016
  }
  getTime = function (num) {
    var d = new Date(num);
    return d.toLocaleTimeString();
  }
  getDateTime = function (num) {
    var d = new Date(num);
    return d.toLocaleString();
  }

  getDistance = function (num) {
    num = num / 1000
    num = num.toFixed(2)
    return num;
  }

  showMap(x) {
    this.zoom = 17;
    var image = '';
    var name = '';
    var index;
    for (var i = 0; i < x.length; i++) {
      this.lat = x[i].lat;
      this.lng = x[i].lon;
      // if (x[i].remark == 'UnusualBreaks') {
      //   image = 'assets/map/blue.png';
      // } else if (x[i].remark == 'Overspeed') {
      //   image = 'assets/map/red.png';
      // } else {
      //   image = 'assets/map/green.png';
      // }
      // image = 'assets/img/elephant.png';
      if (i == 0)
        image = 'data:image/jpeg;base64,' + x[i].animal.picByte;
      // image = 'https://img.icons8.com/metro/45/827E72/marker.png';
      else
        image = ''
      // image = 'data:image/jpeg;base64,' + x[i].animal.picByte;
      index = i;
      name = 'Tag Id ' + x[i].imei + ' ' + this.datepipe.transform(x[i].timestamp * 1000, 'dd/MM HH:mm a');
      this.markers.push({
        'lat': x[i].lat,
        'lng': x[i].lon,
        'image': image,
        'name': name,
        'date': x[i].timestamp,
        'color': x[i].colorCode,
        'index': JSON.stringify(index)
      })
    }
    this.markers[0].index = null;
    this.lat = this.markers[0].lat;
    this.lng = this.markers[0].lng;
  }

  showMap2(x) {
    // alert(x);
    this.zoom = 15;
    var image = '';
    var name = '';
    var index;
    for (var i = 0; i < x.length; i++) {
      this.lat = x[i].lat;
      this.lng = x[i].lon;

      // image = 'data:image/jpeg;base64,' + x[i].tracker.animalname.animal.picByte;
      // image = 'https://img.icons8.com/metro/45/827E72/marker.png';
      index = i + 1;
      image = 'https://img.icons8.com/metro/45/' + x[i].colorCode + '/marker.png';

      name = 'Tag Id ' + x[i].imei + ' ' + this.datepipe.transform(x[i].timestamp * 1000, 'dd/MM HH:mm a');
      this.markers.push({
        'lat': x[i].lat,
        'lng': x[i].lon,
        'image': image,
        'name': name,
        'date': x[i].timestamp,
        'color': x[i].colorCode,
        'index': JSON.stringify(index)
      })
    }
  }

  ngOnInit() {
    // let dashboard = localStorage.getItem('dashboard');
    // if (dashboard == 'yes') {
    let form = JSON.parse(localStorage.getItem('formdetails'));
    // console.log(form);
    // this.dashboardShow = true;
    this.getAllTrackers();
    this.startDate = new Date(form.startDate);
    this.endDate = new Date(form.endDate);
    let trackerId = form.trackers;
    this.dashboardtrackerId = trackerId;
    // console.log(trackerId);
    // } else {
    //   this.dashboardShow = false;
    // }
  }

  getAllTrackers() {
    this.http.get<any>(AppConstants.BASE_URL + AppConstants.ALL_TRACKERS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        this.trackerList = data.responseData;
        let logTrackers = [];
        this.response.responseData.forEach(r => {
          if (this.loogedUOrganization == r.organization.name) {
            logTrackers.push(r);
          }
        });
        // console.log(logTrackers);
        this.trackerList = logTrackers;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  updateMap() {
    var start = new Date(this.startDate);
    start.setHours(0)
    start.setMinutes(0)
    start.setSeconds(0)
    var end = new Date(this.endDate);
    end.setHours(23)
    end.setMinutes(59)
    end.setSeconds(59)
    let trackingObj = { startDate: start.getTime(), endDate: end.getTime(), trackerId: this.dashboardtrackerId };
    // console.log(trackingObj);
    this.loader = 'true'
    this.http.post<any>(AppConstants.BASE_URL + AppConstants.ALL_TRACKINGS_TRACKER, trackingObj)
      .subscribe((data) => {
        this.loader = "false";
        if (data.responseData.length > 0) {
          this.response = JSON.parse(JSON.stringify(data));
          // for (var i = 0; i < this.response.responseData.length; i++) {
          this.tripDetails = this.response.responseData[0];
          // }
          this.markers = [];
          this.showMap(this.response.responseData);
        } else {
          this._snackBar.open('No Tracking Details To Display!', 'OK', {
            duration: 2000, verticalPosition: "top"
          });
        }
      }, err => {
        this.loader = "false";
        if (err.status === 0)
          this._snackBar.open('Server is Down!', 'OK', {
            duration: 2000, verticalPosition: "top"
          });
      });
  }

  // google maps zoom level
  zoom: number = 30;

  // initial center position for the map
  lat: number = 15.317277;
  lng: number = 75.713890;

  clickedMarker(label: string, index: number) {
    // console.log(`clicked the marker: ${label || index}`)
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     draggable: true
  //   });
  // }

  backClick() {
    this.router.navigate(['/tripdetails']);
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    // console.log('dragEnd', m, $event);
  }

  // markers: marker[] = [
  //   {
  //     lat: 12.903870,
  //     lng: 77.545710,
  //     label: 'A',
  //     draggable: true
  //   }
  // ]
  markers: marker[] = [

  ]
}


// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  image: string;
  name: string;
  date: number;
  color: string;
  index: any;
  // label?: string;
  // draggable: boolean;
}


