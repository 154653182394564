import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class UserAuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (atob(localStorage.getItem('name')) != null && atob(localStorage.getItem('roleName')) != null) {
      return true;
    } else {
      return false;
    }

  }

  canActivateChild() {
    // console.log('checking child route access');
    return true;
  }

}