import { Component, OnInit, VERSION, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';
import { AppConstants } from 'src/app/AppConstants';
@Component({
  selector: 'app-addtrackers',
  templateUrl: './addtrackers.component.html',
  styleUrls: ['./addtrackers.component.css']
})
export class AddtrackersComponent implements OnInit {

  collarKeyShow: boolean;
  public breakpoint: number;
  public addTrackerForm: FormGroup;
  wasFormChanged = false;
  response = { code: null, responseData: null, message: null };
  animals: any;
  collars: any;
  divisions: any;
  subdivisions: any;
  ranges: any;
  circles: any;
  animalnames: any;
  loogedUserRole: string;
  loogedUOrganization: string;
  organizations: any;
  constructor(
    private router: Router,
    private alerts: AlertsService, private _snackBar: MatSnackBar,
    private http: HttpClient,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    this.getAllAnimals();
    this.getAllCollars();
    this.getAllOrganizations();

    // this.getAllAnimalNames();
    // this.getAllRanges();
    // this.getAllSubDivisions();
    // this.getAllDivisions();
    // this.getAllCircles();
  }

  public ngOnInit(): void {
    if (this.data != null) {
      let data = JSON.parse(JSON.stringify(this.data));
      this.addTrackerForm = this.fb.group({
        tagId: [data.tagId, [Validators.required]],
        imei: [data.imei],
        animalId: [data.animal.id, [Validators.required]],
        log: [data.log, [Validators.required]],
        organizationId: [data.organization.id, [Validators.required]]
      });
    } else {
      this.addTrackerForm = this.fb.group({
        tagId: [null, [Validators.required]],
        imei: [null, [Validators.required]],
        animalId: [null, [Validators.required]],
        log: [60, [Validators.required]],
        organizationId: [null, [Validators.required]]
      });
    }
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
    this.router.onSameUrlNavigation = 'reload';
  }

  public onAddCus(): void {
    this.markAsDirty(this.addTrackerForm);
  }

  openDialog(): void {
    this.dialog.closeAll();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  enableCollarKey(x) {
    this.http.get(AppConstants.BASE_URL + AppConstants.GET_COLLAR + "?id=" + x)
      .subscribe((data) => {
        this.response = JSON.parse(JSON.stringify(data));
        if (this.response.responseData.name == 'AWT') {
          this.collarKeyShow = false;
        } else {
          this.collarKeyShow = true;
        }
      });
  }

  getAllOrganizations() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_ORGANIZATIONS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let organizations = this.response.responseData;
        this.organizations = organizations;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllAnimals() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_ANIMALS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let animals = this.response.responseData;
        this.animals = animals;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllAnimalNames() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_ANIMALNAMES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logAnimals = [];
        this.response.responseData.forEach(r => {

          if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.organization.name) {
              logAnimals.push(r);
            }
          }
        });
        this.animalnames = logAnimals;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllCollars() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_COLLAR)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let collars = this.response.responseData;
        this.collars = collars;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllCircles() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_CIRCLES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logcircles = [];
        this.response.responseData.forEach(r => {

          if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.organization.name) {
              logcircles.push(r);
            }
          }

        });
        this.circles = logcircles;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllSubDivisionsByDivision(x) {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_DIVISION_SUBDIVISIONS + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let subdivisions = this.response.responseData;
        this.subdivisions = subdivisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllRanges() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_RANGES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logranges = [];
        this.response.responseData.forEach(r => {

          if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.organization.name) {
              logranges.push(r);
            }
          }

        });
        this.ranges = logranges;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllRangesBySubDivision(x) {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_SUBDIVISION_RANGES + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let ranges = this.response.responseData;
        this.ranges = ranges;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllSubDivisions() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_SUBDIVISIONS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logsubdivisions = [];
        this.response.responseData.forEach(r => {

          if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.organization.name) {
              logsubdivisions.push(r);
            }
          }

        });
        this.subdivisions = logsubdivisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllDivisionsByCircle(x) {
    this.http.get(AppConstants.BASE_URL + AppConstants.ALL_CIRCLE_DIVISIONS + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let divisions = this.response.responseData;
        this.divisions = divisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllDivisions() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_DIVISIONS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logdivisions = [];
        this.response.responseData.forEach(r => {

          if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.organization.name) {
              logdivisions.push(r);
            }
          }

        });
        this.divisions = logdivisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  addTracker() {
    if (this.data != null) {
      let x = this.data;
      let y = this.addTrackerForm.value;
      let editTracker = { id: x.id, log: y.log, tagId: y.tagId, imei: y.imei, animal: { id: y.animalId }, organization: { id: y.organizationId } };
      // console.log(editTracker)
      this.http.post(AppConstants.BASE_URL + AppConstants.UPDATE_TRACKER, editTracker)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Tracker Updated Successfully !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('Tracker already registered !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else if (this.response.code == 202) {
            this._snackBar.open('Tracker with IMEI already registered !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    } else {
      let y = this.addTrackerForm.value;
      let addTracker = { tagId: y.tagId, log: y.log, imei: y.imei, animal: { id: y.animalId }, organization: { id: y.organizationId } };
      // console.log(addTracker);
      this.http.post(AppConstants.BASE_URL + AppConstants.CREATE_TRACKER, addTracker)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('Tracker already registered !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else if (this.response.code == 202) {
            this._snackBar.open('Tracker with IMEI already registered !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }

  }

}