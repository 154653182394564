import { Component, OnInit, VERSION, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';
import { AppConstants } from 'src/app/AppConstants';

@Component({
  selector: 'app-addrole',
  templateUrl: './addrole.component.html',
  styleUrls: ['./addrole.component.css']
})
export class AddroleComponent implements OnInit {

  roles: any;
  public breakpoint: number;
  public addRoleForm: FormGroup;
  wasFormChanged = false;
  response = { code: null, responseData: null, message: null };
  constructor(private router: Router,
    private alerts: AlertsService, private _snackBar: MatSnackBar,
    private http: HttpClient,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.getRoles();
    // console.log(data);
  }

  getRoles() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.GET_ROLES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let roles = this.response.responseData;
        this.roles = roles;
      });
  }

  public ngOnInit(): void {
    if (this.data != null) {
      let data = JSON.parse(JSON.stringify(this.data));
      this.addRoleForm = this.fb.group({
        roleName: [data.roleName, [Validators.required]],
      });
    } else {
      this.addRoleForm = this.fb.group({
        roleName: [null, [Validators.required]],
      });
    }
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
    this.router.onSameUrlNavigation = 'reload';
  }

  public onAddCus(): void {
    this.markAsDirty(this.addRoleForm);
  }

  openDialog(): void {
    // console.log(this.wasFormChanged);
    // if(this.addRoleForm.dirty) {
    //   const dialogRef = this.dialog.open(DeleteComponent, {
    //     width: '340px',
    //   });
    // } else {
    this.dialog.closeAll();
    // }
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  addRole() {
    // console.log(this.addRoleForm.value);
    if (this.data != null) {
      let x = this.data;
      let y = this.addRoleForm.value;
      let editRole = { id: x.id, roleName: y.roleName };
      // console.log(editRole)
      this.http.post(AppConstants.BASE_URL + AppConstants.UPDATE_ROLE, editRole)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Role Updated Successfully!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('Role name is already registered!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    } else {
      this.http.post(AppConstants.BASE_URL + AppConstants.SAVE_ROLE, this.addRoleForm.value)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('Role name is already registered!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }

  }

}
