import { Component, OnInit, VERSION, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';
import { AppConstants } from 'src/app/AppConstants';
@Component({
  selector: 'app-addbeat',
  templateUrl: './addbeat.component.html',
  styleUrls: ['./addbeat.component.css']
})
export class AddbeatComponent implements OnInit {

  public breakpoint: number;
  public addBeatForm: FormGroup;
  wasFormChanged = false;
  response = { code: null, responseData: null, message: null };
  divisions: any;
  subdivisions: any;
  circles: any;
  ranges: any;
  sections: any;
  constructor(
    private router: Router,
    private alerts: AlertsService, private _snackBar: MatSnackBar,
    private http: HttpClient,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.getAllSections();
    this.getAllRanges();
    this.getAllSubDivisions();
    this.getAllDivisions();
    this.getAllCircles();
  }

  public ngOnInit(): void {
    if (this.data != null) {
      let data = JSON.parse(JSON.stringify(this.data));
      this.addBeatForm = this.fb.group({
        name: [data.name, [Validators.required]],
        sectionId: [data.section.id, [Validators.required]],
        rangeId: [data.section.rangeModel.id, [Validators.required]],
        subdivisionId: [data.section.rangeModel.subdivision.id, [Validators.required]],
        divisionId: [data.section.rangeModel.subdivision.division.id, [Validators.required]],
        circleId: [data.section.rangeModel.subdivision.division.circle.id, [Validators.required]]
      });
    } else {
      this.addBeatForm = this.fb.group({
        name: [null, [Validators.required]],
        sectionId: [null, [Validators.required]]
      });
    }
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
    this.router.onSameUrlNavigation = 'reload';
  }

  public onAddCus(): void {
    this.markAsDirty(this.addBeatForm);
  }

  openDialog(): void {
    this.dialog.closeAll();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  getAllCircles() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_CIRCLES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let circles = this.response.responseData;
        this.circles = circles;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllSubDivisionsByDivision(x) {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_DIVISION_SUBDIVISIONS + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let subdivisions = this.response.responseData;
        this.subdivisions = subdivisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllSections() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_SECTIONS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let sections = this.response.responseData;
        this.sections = sections;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllSectionsByRange(x) {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_RANGE_SECTIONS + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let sections = this.response.responseData;
        this.sections = sections;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllRanges() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_RANGES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let ranges = this.response.responseData;
        this.ranges = ranges;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllRangesBySubDivision(x) {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_SUBDIVISION_RANGES + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let ranges = this.response.responseData;
        this.ranges = ranges;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllSubDivisions() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_SUBDIVISIONS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let subdivisions = this.response.responseData;
        this.subdivisions = subdivisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllDivisionsByCircle(x) {
    this.http.get(AppConstants.BASE_URL + AppConstants.ALL_CIRCLE_DIVISIONS + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let divisions = this.response.responseData;
        this.divisions = divisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllDivisions() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_DIVISIONS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let divisions = this.response.responseData;
        this.divisions = divisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  addBeat() {
    if (this.data != null) {
      let x = this.data;
      let y = this.addBeatForm.value;
      let editBeat = { id: x.id, name: y.name, section: { id: y.sectionId } };
      // console.log(editBeat)
      this.http.post(AppConstants.BASE_URL + AppConstants.UPDATE_BEAT, editBeat)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Beat Updated Successfully !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('Beat with Name already registered !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    } else {
      let y = this.addBeatForm.value;
      let addBeat = { name: y.name, section: { id: y.sectionId } };
      this.http.post(AppConstants.BASE_URL + AppConstants.CREATE_BEAT, addBeat)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('Beat with name already registered !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }

  }

}