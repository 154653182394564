import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialogConfig, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../AppConstants'; import { Title } from '@angular/platform-browser';
import { AdduserComponent } from './adduser/adduser.component';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';
import { AddroleComponent } from './addrole/addrole.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  roles: any;
  loogedUserRole: string;
  loogedUOrganization: string;

  ngOnInit() {
  }

  displayedColumns = ['index', 'name', 'password', 'role', 'organization', 'action'];
  dataSource = new MatTableDataSource();

  displayedColumns2 = ['index', 'id', 'roleName', 'action'];
  dataSource2 = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  response = { code: null, responseData: null, message: null };
  constructor(private titleService: Title, public dialog: MatDialog, private alerts: AlertsService, private _snackBar: MatSnackBar, private http: HttpClient) {
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    if (this.loogedUserRole == 'Admin' || this.loogedUserRole == 'SuperAdmin')
      this.getUsers();
    // this.getUsers();
    this.getRoles();
    this.titleService.setTitle('Gaja Users');
  }

  getUsers() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.GET_USERS)
      .subscribe((data) => {
        this.dataSource.data = [];
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logUsers = [];
        this.response.responseData.forEach(r => {
          if (this.loogedUserRole == 'SuperAdmin') {
            if (r.role.roleName == 'SuperAdmin' || r.role.roleName == 'Admin')
              logUsers.push(r);
          } else if (this.loogedUserRole == 'Admin') {
            if (r.organization != null && this.loogedUOrganization == r.organization.name) {
              if (r.role.roleName == 'Admin' || r.role.roleName == 'RegularUser')
                logUsers.push(r);
            }
          }
        });
        this.dataSource.data = logUsers;
      });
  }

  getRoles() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.GET_ROLES)
      .subscribe((data) => {
        this.dataSource2.data = [];
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logRoles = [];
        this.response.responseData.forEach(r => {
          // if (this.loogedUserRole == 'SuperAdmin') {
          //   if (r.roleName == 'SuperAdmin' || r.roleName == 'Admin')
          //     logRoles.push(r);
          // } else if (this.loogedUserRole == 'Admin') {
          //   if (r.roleName == 'Admin' || r.roleName == 'RegularUser')
          //     logRoles.push(r);
          // }
          logRoles.push(r);
        });

        this.roles = logRoles;
        this.dataSource2.data = logRoles;
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  applyFilter2(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource2.filter = filterValue;
  }

  addUser(): void {
    const dialogRef = this.dialog.open(AdduserComponent, {
      width: '640px', disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getUsers();
      if (this.loogedUserRole == 'Admin' || this.loogedUserRole == 'SuperAdmin')
        this.getUsers();
    });
  }

  editUser(event, params) {
    event.stopPropagation();
    const payload = params;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "640px";
    dialogConfig.data = Object.assign({}, payload);

    const dialogRef = this.dialog.open(
      AdduserComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      // this.getUsers();
      if (this.loogedUserRole == 'Admin' || this.loogedUserRole == 'SuperAdmin')
        this.getUsers();
    });
  }

  deleteUser(x) {
    var r = confirm("Are you sure want to delete User ? ");
    if (r == true) {
      let deleteUserObj = { id: x }
      // console.log(deleteUserObj);
      this.http.post(AppConstants.BASE_URL + AppConstants.DELETE_USER, deleteUserObj)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('User Deleted Successfully!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            // this.getUsers();
            if (this.loogedUserRole == 'Admin' || this.loogedUserRole == 'SuperAdmin')
              this.getUsers();
            this.getRoles();
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }
    else {
      // txt = "You pressed Cancel!";
    }
  }

  addRole(): void {
    const dialogRef = this.dialog.open(AddroleComponent, {
      width: '640px', disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getRoles();
    });
  }

  editRole(event, params) {
    event.stopPropagation();
    const payload = params;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "640px";
    dialogConfig.data = Object.assign({}, payload);

    const dialogRef = this.dialog.open(
      AddroleComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      this.getRoles();
    });
  }

  deleteRole(x) {
    var r = confirm("Are you sure want to delete Role ? ");
    if (r == true) {
      let deleteRoleObj = { id: x }
      // console.log(deleteRoleObj);
      this.http.post(AppConstants.BASE_URL + AppConstants.DELETE_ROLE, deleteRoleObj)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Role Deleted Successfully!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            // this.getUsers();
            if (this.loogedUserRole == 'Admin' || this.loogedUserRole == 'SuperAdmin')
              this.getUsers();
            this.getRoles();
          }
          else {
            this._snackBar.open('Role Cannot Be Deleted Since This Role Has Users !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }
    else {
      // txt = "You pressed Cancel!";
    }
  }

}
