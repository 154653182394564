import { Component, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from '../AppConstants';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-trackingdetails',
  templateUrl: './trackingdetails.component.html',
  styleUrls: ['./trackingdetails.component.css']
})
export class TrackingdetailsComponent implements OnInit {
  vehicles = [];

  displayedColumns = ['index', 'animal', 'animalType', 'tracker', 'tagId', 'imei', 'lastSyncDate', 'action', 'action2'];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(true, []);
  userForm: FormGroup;
  trackerList = [];
  trackerItems = [];
  trackerIds = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  response = { code: null, responseData: null, message: null };
  loader = "false";
  maxDate: Date;
  animals: any;
  trackers: any;
  loogedUserRole: string;
  loogedUOrganization: string;

  constructor(private router: Router, private http: HttpClient, private alerts: AlertsService, private _snackBar: MatSnackBar, private titleService: Title, private datePipe: DatePipe) {
    let dte = new Date();
    // dte.setDate(dte.getDate() - 1);
    dte.setHours(0)
    dte.setMinutes(0)
    dte.setSeconds(1)
    let dte2 = new Date();
    // dte2.setDate(dte2.getDate() - 1);
    dte2.setHours(23)
    dte2.setMinutes(59)
    dte2.setSeconds(59)
    this.userForm = new FormGroup({
      date1: new FormControl(dte, [Validators.required]),
      date2: new FormControl(dte2, [Validators.required]),
      trackers: new FormControl('', [Validators.required])
    });
    this.titleService.setTitle('Gaja Tracking Details');
    this.maxDate = new Date();
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));

    this.displayedColumns = ['index', 'tagId', 'animal', 'animaltype', 'imei', 'lastSyncDate', 'action', 'action2'];
  }

  ngOnInit() {
    this.getAnimals();
    this.onLoad();
    this.getAllTrackers();
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'value',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  onItemSelect(item: any) {

  }

  onSelectAll(items: any) {

  }

  onDeSelectAll(items: any) {
    this.trackerIds = [];
  }

  getAnimals() {
    this.http.get(AppConstants.BASE_URL + AppConstants.ALL_ANIMALS)
      .subscribe((data) => {
        this.response = JSON.parse(JSON.stringify(data));
        let animals = this.response.responseData;
        this.animals = animals;
      });
  }

  getAllTrackers() {
    this.http.get<any>(AppConstants.BASE_URL + AppConstants.ALL_TRACKERS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logTrackers = [];
        this.response.responseData.forEach(r => {
          if (this.loogedUOrganization == r.organization.name) {
            logTrackers.push(r);
          }
        });
        // console.log(logTrackers);
        this.trackers = logTrackers;
        this.trackerList = [];
        for (var i = 0; i < logTrackers.length; i++) {
          let obj = logTrackers[i];
          this.trackerList.push({ id: obj.id, value: obj.animalname.name + "-" + obj.imei });
        }
        // console.log(this.trackerList);
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllTrackersByAnimal(x) {
    this.trackerIds = [];
    this.userForm.get('trackers').setValue([]);
    this.http.get(AppConstants.BASE_URL + AppConstants.ALL_ANIMAL_TRACKERS + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let trackers = this.response.responseData;

        let logTrackers = [];
        this.response.responseData.forEach(r => {
          if (this.loogedUOrganization == r.organization.name) {
            logTrackers.push(r);
          }
        });
        // console.log(logTrackers);
        this.trackers = logTrackers;
        this.trackerList = [];
        for (var i = 0; i < logTrackers.length; i++) {
          let obj = logTrackers[i];
          this.trackerList.push({ id: obj.id, value: obj.animalname.name + "-" + obj.imei });
        }
        // console.log(this.trackerList);
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  onLoad() {
    this.loader = "true";
    let vehicles = [];
    var dte = new Date();
    // dte.setDate(dte.getDate() - 1);
    dte.setHours(0)
    dte.setMinutes(0)
    dte.setSeconds(0)
    var dte2 = new Date();
    // dte2.setDate(dte2.getDate() - 1);
    dte2.setHours(23)
    dte2.setMinutes(59)
    dte2.setSeconds(59)
    let trackingObj = { startDate: dte.getTime(), endDate: dte2.getTime(), trackers: null, org: this.loogedUOrganization }
    // localStorage.setItem('startDate', dte.getTime());
    // localStorage.setItem('endDate', dte2.getTime());
    localStorage.setItem('formdetails', JSON.stringify(trackingObj));
    // console.log(trackingObj)
    this.http.post(AppConstants.BASE_URL + AppConstants.ALL_TRACKER_TRACKINGS, trackingObj
    ).subscribe((data) => {
      this.dataSource.data = [];
      this.loader = "false";
      // console.log(data);
      this.response = JSON.parse(JSON.stringify(data));
      // console.log(this.response.responseData);
      if (this.response.code == 200) {
        this.dataSource.data = this.response.responseData;
      } else if (this.response.code == 201) {
        this._snackBar.open('No Tracking Details To Display !', 'OK', {
          duration: 2000, verticalPosition: "top"
        });
      } else {
        this._snackBar.open('Server is Down!', 'OK', {
          duration: 2000, verticalPosition: "top"
        });
      }
    });
  }

  onSubmit() {
    this.loader = "true";
    let trackers = [];
    for (var i = 0; i < this.userForm.value.trackers.length; i++) {
      trackers.push(this.userForm.value.trackers[i].id);
    }
    let start = new Date(this.userForm.value.date1);
    let end = new Date(this.userForm.value.date2);
    if (end < start) {
      // console.log(this.datePipe.transform(start, "dd-MM-yyyy") + " > " + this.datePipe.transform(end, "dd-MM-yyyy"));
      this.loader = "false";
      this._snackBar.open('End date should be grater then start date!', 'OK', {
        duration: 2000, verticalPosition: "top"
      });
    } else {
      var dte = new Date(start);
      dte.setHours(0)
      dte.setMinutes(0)
      dte.setSeconds(0)
      // console.log(dte.getTime());
      var dte2 = new Date(end);
      dte2.setHours(23)
      dte2.setMinutes(59)
      dte2.setSeconds(59)
      let trackingObj = { startDate: dte.getTime(), endDate: dte2.getTime(), trackers: trackers, org: this.loogedUOrganization }
      localStorage.setItem('formdetails', JSON.stringify(trackingObj));
      // localStorage.setItem('endDate', dte2.getTime());
      // console.log(trackingObj)
      this.http.post(AppConstants.BASE_URL + AppConstants.ALL_TRACKER_TRACKINGS, trackingObj
      ).subscribe((data) => {
        this.dataSource.data = [];
        this.loader = "false";
        // console.log(data);
        // console.log(data[0][0]);
        this.response = JSON.parse(JSON.stringify(data));
        if (this.response.code == 200) {
          this.dataSource.data = this.response.responseData;
        } else if (this.response.code == 201) {
          this._snackBar.open('No Tracking Details To Display!', 'OK', {
            duration: 2000, verticalPosition: "top"
          });
        } else {
          this._snackBar.open('Server Error!', 'OK', {
            duration: 2000, verticalPosition: "top"
          });
        }
      },
        (err) => {
          this.loader = "false";
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
    }
  }

  getMap(x) {
    // console.log(x);
    // localStorage.setItem('tripName', x[0].name);
    localStorage.setItem('trackerId', x[0].id);
    localStorage.setItem('quick', "no");
    // console.log(localStorage.getItem('trackerId'))
    // localStorage.setItem('zoneName', x.zoneName);
    // localStorage.setItem('tripdetails', JSON.stringify(x));
    // this.router.navigate(['/mapview']);
    window.open(AppConstants.ANG_URL + 'mapview', '_blank');
  }

  getMap2(x) {
    // localStorage.setItem('tripName', x[0].name);
    localStorage.setItem('trackerId', x[0].id);
    localStorage.setItem('quick', "yes");
    // console.log(localStorage.getItem('trackerId'))
    // localStorage.setItem('zoneName', x.zoneName);
    // localStorage.setItem('tripdetails', JSON.stringify(x));
    // this.router.navigate(['/mapview']);
    window.open(AppConstants.ANG_URL + 'mapview', '_blank');
  }

  ngAfterViewInit() {
    // this.sort.sort({ id: 'lastSyncDate', start: 'desc', disableClear: false });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

}