import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MapviewComponent } from './mapview/mapview.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FullLayoutComponent } from './layouts/full-layout.component';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './login/login.component';
import { CircleComponent } from './circle/circle.component';
import { TrackingdetailsComponent } from './trackingdetails/trackingdetails.component';
import { AdminAuthGuard } from './guards/admin-auth-gaurd';
import { DivisionComponent } from './division/division.component';
import { SubdivisionComponent } from './subdivision/subdivision.component';
import { RangeComponent } from './range/range.component';
import { SectionComponent } from './section/section.component';
import { BeatComponent } from './beat/beat.component';
import { AnimalComponent } from './animal/animal.component';
import { TrackerComponent } from './tracker/tracker.component';
import { OrganizationComponent } from './organization/organization.component';
import { TrackersComponent } from './trackers/trackers.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    canActivate: [AdminAuthGuard],
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'user',
        component: UserComponent,
      },
      {
        path: 'circle',
        component: CircleComponent,
      },
      {
        path: 'division',
        component: DivisionComponent,
      },
      {
        path: 'subdivision',
        component: SubdivisionComponent,
      },
      {
        path: 'range',
        component: RangeComponent,
      },
      {
        path: 'section',
        component: SectionComponent,
      },
      {
        path: 'beat',
        component: BeatComponent,
      },
      {
        path: 'animal',
        component: AnimalComponent,
      },
      {
        path: 'organization',
        component: OrganizationComponent,
      },
      {
        path: 'tracker',
        component: TrackerComponent,
      },
      {
        path: 'trackers',
        component: TrackersComponent,
      },
      {
        path: 'trackingdetails',
        component: TrackingdetailsComponent,
      },
      {
        path: 'mapview',
        component: MapviewComponent,
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
