export class AppConstants {

    //local
    // public static BASE_URL = 'http://localhost:8080/gajacollars/';
    // public static KML_URL = 'https://hejjecollars.in/image/';
    // public static DWNLD_URL = 'https://hejjecollars.in/image/Trackings/';
    // public static ANG_URL = 'http://localhost:4200/#/';

    // Gaja collars
    // public static BASE_URL = 'http://139.84.142.197:8080/gajacollars/';
    // public static ANG_URL = 'http://139.84.142.197:8080/#/';
    // public static KML_URL = 'http://139.84.142.197:8080/image/';
    // public static DWNLD_URL = 'http://139.84.142.197:8080/image/Trackings/';

    // public static BASE_URL = 'https://hejjecollars.in/gajacollars/';
    // public static KML_URL = 'https://hejjecollars.in/image/';
    // public static DWNLD_URL = 'https://hejjecollars.in/image/Trackings/';
    // public static ANG_URL = 'https://hejjecollars.in/#/';

    public static BASE_URL = 'http://gaja.imaxine.in:8090/gajacollars/';
    public static KML_URL = 'http://gaja.imaxine.in:8090/image/';
    public static DWNLD_URL = 'http://gaja.imaxine.in:8090/image/Trackings/';
    public static ANG_URL = 'http://gaja.imaxine.in:8090/#/';

    public static CREATE_CIRCLE = 'circle/create';
    public static GET_CIRCLE = 'circle/get';
    public static UPDATE_CIRCLE = 'circle/update';
    public static DELETE_CIRCLE = 'circle/delete';
    public static ALL_CIRCLES = 'circle/all';
    public static ALL_CIRCLE_DIVISIONS = 'circle/allDivisions';

    public static CREATE_DIVISION = 'division/create';
    public static GET_DIVISION = 'division/get';
    public static UPDATE_DIVISION = 'division/update';
    public static DELETE_DIVISION = 'division/delete';
    public static ALL_DIVISIONS = 'division/all';
    public static ALL_DIVISION_SUBDIVISIONS = 'division/allSubdivisions';

    public static CREATE_SUBDIVISION = 'subdivision/create';
    public static GET_SUBDIVISION = 'subdivision/get';
    public static UPDATE_SUBDIVISION = 'subdivision/update';
    public static DELETE_SUBDIVISION = 'subdivision/delete';
    public static ALL_SUBDIVISIONS = 'subdivision/all';
    public static ALL_SUBDIVISION_RANGES = 'subdivision/allRanges';

    public static CREATE_RANGE = 'range/create';
    public static GET_RANGE = 'range/get';
    public static UPDATE_RANGE = 'range/update';
    public static DELETE_RANGE = 'range/delete';
    public static ALL_RANGES = 'range/all';
    public static ALL_RANGE_SECTIONS = 'range/allSections';

    public static CREATE_SECTION = 'section/create';
    public static GET_SECTION = 'section/get';
    public static UPDATE_SECTION = 'section/update';
    public static DELETE_SECTION = 'section/delete';
    public static ALL_SECTIONS = 'section/all';
    public static ALL_SECTION_BEATS = 'section/allBeats';

    public static CREATE_BEAT = 'beat/create';
    public static GET_BEAT = 'beat/get';
    public static UPDATE_BEAT = 'beat/update';
    public static DELETE_BEAT = 'beat/delete';
    public static ALL_BEATS = 'beat/all';

    public static CREATE_ANIMAL = 'animal/create';
    public static GET_ANIMAL = 'animal/get';
    public static UPDATE_ANIMAL = 'animal/update';
    public static DELETE_ANIMAL = 'animal/delete';
    public static ALL_ANIMALS = 'animal/all';

    public static CREATE_ORGANIZATION = 'organization/create';
    public static GET_ORGANIZATION = 'organization/get';
    public static UPDATE_ORGANIZATION = 'organization/update';
    public static DELETE_ORGANIZATION = 'organization/delete';
    public static ALL_ORGANIZATIONS = 'organization/all';
    public static ORGANIZATION_KML = 'organization/kml';

    public static CREATE_ANIMALNAME = 'animalname/create';
    public static GET_ANIMALNAME = 'animalname/get';
    public static UPDATE_ANIMALNAME = 'animalname/update';
    public static DELETE_ANIMALNAME = 'animalname/delete';
    public static ALL_ANIMALNAMES = 'animalname/all';
    public static CHANGE_ANIMAL_COLOR = 'animalname/changeAnimalColor';

    public static CREATE_COLLAR = 'collar/create';
    public static GET_COLLAR = 'collar/get';
    public static UPDATE_COLLAR = 'collar/update';
    public static DELETE_COLLAR = 'collar/delete';
    public static ALL_COLLAR = 'collar/all';

    public static CREATE_TRACKER = 'tracker/create';
    public static GET_TRACKER = 'tracker/get';
    public static UPDATE_TRACKER = 'tracker/update';
    public static DELETE_TRACKER = 'tracker/delete';
    public static ALL_TRACKERS = 'tracker/all';
    public static ALL_ANIMAL_TRACKERS = 'tracker/alltrackers';

    public static LIVE_TRACKING = 'awt/livedata';
    public static REPLAY_TRACKING = 'awt/replaydata';

    public static CREATE_TRACKING = 'tracking/create';
    public static GET_TRACKING = 'tracking/get';
    public static UPDATE_TRACKING = 'tracking/update';
    public static DELETE_TRACKING = 'tracking/delete';
    public static ALL_TRACKING = 'tracking/all';
    public static LATEST_TRACKINGS = 'tracking/latesttrackings';
    public static ALL_TRACKER_TRACKINGS = 'tracking/alltrackings';
    public static ALL_TRACKINGS_TRACKER = 'tracking/trackingsoftracker';
    public static QUICK_TRACKINGS_TRACKER = 'tracking/quicktrackingsoftracker';
    public static ALL_TRACKINGS_LIVE = 'tracking/livetrackings';
    public static ALL_TRACKINGS_LIVE_TRACKER = 'tracking/livetrackingsoftracker';
    public static DOWNLOAD_TRACKINGS_DETAILS = 'tracking/downloadtrackings';

    public static SAVE_USER = 'user/saveUser';
    public static GET_USERS = 'user/getUser';
    public static UPDATE_USER = 'user/updateUser';
    public static DELETE_USER = 'user/deleteUser';

    public static SAVE_ROLE = 'user/saveRole';
    public static GET_ROLES = 'user/getRoles';
    public static GET_ROLE = 'user/getRole';
    public static UPDATE_ROLE = 'user/updateRole';
    public static DELETE_ROLE = 'user/deleteRole';

    public static LOGIN = 'user/UserLogin';

    // unused
    static GET_ALL_VEHICLE_DRIVER: string;
    static GET_LAT_LONG_BY_TRIP: string;
    static DELETE_VEHICLE_TRACKS: string;
    static GET_TRIPS: string;
    static GET_VEHICLE_BY_DATE: string;
    static TOTAL_OFFENCES: string;

}