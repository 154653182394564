import { Component, OnInit, VERSION, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';
import { AppConstants } from 'src/app/AppConstants';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {
  roles: any;
  public breakpoint: number;
  public addUserForm: FormGroup;
  wasFormChanged = false;
  response = { code: null, responseData: null, message: null };
  divisions: any;
  subdivisions: any;
  ranges: any;
  circles: any;
  loogedUserRole: string;
  organizations: any;
  loogedUOrganization: string;
  constructor(
    private router: Router,
    private alerts: AlertsService, private _snackBar: MatSnackBar,
    private http: HttpClient,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    this.getRoles();
    this.getOrganizations();
    this.getAllRanges();
    this.getAllSubDivisions();
    this.getAllDivisions();
    this.getAllCircles();
    // console.log(data);
  }

  getRoles() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.GET_ROLES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logRoles = [];
        this.response.responseData.forEach(r => {
          if (this.loogedUserRole == 'SuperAdmin') {
            if (r.roleName == 'SuperAdmin' || r.roleName == 'Admin')
              logRoles.push(r);
          } else if (this.loogedUserRole == 'Admin') {
            if (r.roleName == 'Admin' || r.roleName == 'RegularUser')
              logRoles.push(r);
          }
        });
        this.roles = logRoles;
      });
  }

  getOrganizations() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_ORGANIZATIONS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logOrganization = [];
        this.response.responseData.forEach(r => {
          if (this.loogedUserRole == 'SuperAdmin') {
            logOrganization.push(r);
          } else if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.name) {
              logOrganization.push(r);
            }
          }
        });
        this.organizations = logOrganization;
      });
  }

  public ngOnInit(): void {
    if (this.data != null) {
      let data = JSON.parse(JSON.stringify(this.data));
      this.addUserForm = this.fb.group({
        name: [data.userName, [Validators.required]],
        password: [data.passWord, [Validators.required]],
        roleId: [data.role.id, [Validators.required]],
        organizationId: [data.organization.id, [Validators.required]]
      });
    } else {
      this.addUserForm = this.fb.group({
        name: [null, [Validators.required]],
        password: [null, [Validators.required]],
        roleId: [null, [Validators.required]],
        organizationId: [null, [Validators.required]]
      });
    }
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
    this.router.onSameUrlNavigation = 'reload';
  }

  public onAddCus(): void {
    this.markAsDirty(this.addUserForm);
  }

  openDialog(): void {
    // console.log(this.wasFormChanged);
    // if(this.addUserForm.dirty) {
    //   const dialogRef = this.dialog.open(DeleteComponent, {
    //     width: '340px',
    //   });
    // } else {
    this.dialog.closeAll();
    // }
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  getAllCircles() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_CIRCLES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let circles = this.response.responseData;
        this.circles = circles;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllSubDivisionsByDivision(x) {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_DIVISION_SUBDIVISIONS + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let subdivisions = this.response.responseData;
        this.subdivisions = subdivisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllRanges() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_RANGES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let ranges = this.response.responseData;
        this.ranges = ranges;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllRangesBySubDivision(x) {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_SUBDIVISION_RANGES + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let ranges = this.response.responseData;
        this.ranges = ranges;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllSubDivisions() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_SUBDIVISIONS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let subdivisions = this.response.responseData;
        this.subdivisions = subdivisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllDivisionsByCircle(x) {
    this.http.get(AppConstants.BASE_URL + AppConstants.ALL_CIRCLE_DIVISIONS + "?id=" + x)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let divisions = this.response.responseData;
        this.divisions = divisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllDivisions() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_DIVISIONS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let divisions = this.response.responseData;
        this.divisions = divisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  addUser() {
    // console.log(this.addUserForm.value);
    if (this.data != null) {
      let x = this.data;
      let y = this.addUserForm.value;
      let editUser = { id: x.id, name: y.name, password: y.password, roleId: y.roleId, organizationId: y.organizationId };
      // console.log(editUser);
      this.http.post(AppConstants.BASE_URL + AppConstants.UPDATE_USER, editUser)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('User Updated Successfully!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('User name is already registered!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    } else {
      let y = this.addUserForm.value;
      let addUser = { name: y.name, password: y.password, roleId: y.roleId, organizationId: y.organizationId };
      // console.log(addUser);
      this.http.post(AppConstants.BASE_URL + AppConstants.SAVE_USER, addUser)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('User name is already registered !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }

  }
}