import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class AdminAuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (atob(localStorage.getItem('name')) != null && atob(localStorage.getItem('roleName')) != null && (atob(localStorage.getItem('roleName')) == 'Admin' || atob(localStorage.getItem('roleName')) == 'RegularUser' || atob(localStorage.getItem('roleName')) == 'SuperAdmin')) {
      return true;
    } else {
      return false;
    }

  }

  canActivateChild() {
    // console.log('checking child route access');
    return true;
  }

}