import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialogConfig, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../AppConstants'; import { Title } from '@angular/platform-browser';
import { AddanimalComponent } from './addanimal/addanimal.component';
import { Router } from '@angular/router';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';
import { AddanimalnameComponent } from './addanimalname/adddanimalname.component';

@Component({
  selector: 'app-animal',
  templateUrl: './animal.component.html',
  styleUrls: ['./animal.component.css']
})
export class AnimalComponent implements OnInit {
  retrievedImage: string;
  loogedUserRole: string;
  loogedUOrganization: string;

  ngOnInit() {
  }

  displayedColumns2 = ['index', 'name', 'pic', 'action'];
  dataSource2 = new MatTableDataSource();

  displayedColumns = ['index', 'name', 'animalname', 'organization', 'action'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  response = { code: null, responseData: null, message: null };
  constructor(private titleService: Title, private router: Router, private http: HttpClient, public dialog: MatDialog, private alerts: AlertsService, private _snackBar: MatSnackBar,
  ) {
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    this.getAllAnimals();
    this.getAllAnimalNames();
    this.titleService.setTitle('Gaja Animals');
  }

  getAllAnimals() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_ANIMALS)
      .subscribe((data) => {
        this.dataSource2.data = [];
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let animals = this.response.responseData;
        this.dataSource2.data = animals;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllAnimalNames() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_ANIMALNAMES)
      .subscribe((data) => {
        this.dataSource.data = [];
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logAnimals = [];
        this.response.responseData.forEach(r => {

          if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.organization.name) {
              logAnimals.push(r);
            }
          }
        });
        this.dataSource.data = logAnimals;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  applyFilter2(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource2.filter = filterValue;
  }

  addAnimal(): void {
    const dialogRef = this.dialog.open(AddanimalComponent, {
      width: '640px', disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllAnimals();
      this.getAllAnimalNames();
    });
  }

  editAnimal(event, params) {
    event.stopPropagation();
    const payload = params;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "640px";
    dialogConfig.data = Object.assign({}, payload);

    const dialogRef = this.dialog.open(
      AddanimalComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      this.getAllAnimals();
      this.getAllAnimalNames();
    });
  }

  deleteAnimal(x) {
    var r = confirm("Are you sure want to delete Animal Type ? ");
    if (r == true) {
      this.http.get(AppConstants.BASE_URL + AppConstants.DELETE_ANIMAL + "?id=" + x)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Animal Type Deleted Successfully!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.getAllAnimals();
            this.getAllAnimalNames();
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }
    else {
      // txt = "You pressed Cancel!";
    }
  }

  addAnimalName(): void {
    const dialogRef = this.dialog.open(AddanimalnameComponent, {
      width: '640px', disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllAnimals();
      this.getAllAnimalNames();
      this.changeColor();
    });
  }

  changeColor() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.CHANGE_ANIMAL_COLOR)
      .subscribe((data) => {
        // console.log(data);
      });
    this.getAllAnimals();
    this.getAllAnimalNames();
  }

  editAnimalName(event, params) {
    event.stopPropagation();
    const payload = params;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "640px";
    dialogConfig.data = Object.assign({}, payload);

    const dialogRef = this.dialog.open(
      AddanimalnameComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      this.getAllAnimals();
      this.getAllAnimalNames();
    });
  }

  deleteAnimalName(x) {
    var r = confirm("Are you sure want to delete Animal? ");
    if (r == true) {
      this.http.get(AppConstants.BASE_URL + AppConstants.DELETE_ANIMALNAME + "?id=" + x)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Animal Deleted Successfully!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.getAllAnimals();
            this.getAllAnimalNames();
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }
    else {
      // txt = "You pressed Cancel!";
    }
  }

}