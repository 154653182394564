import { Component, OnInit, VERSION, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';
import { AppConstants } from 'src/app/AppConstants';
@Component({
  selector: 'app-addorganization',
  templateUrl: './addorganization.component.html',
  styleUrls: ['./addorganization.component.css']
})
export class AddorganizationComponent implements OnInit {

  public breakpoint: number;
  public addOrganizationForm: FormGroup;
  wasFormChanged = false;
  response = { code: null, responseData: null, message: null };
  loogedUserRole: string;
  loogedUOrganization: string;
  constructor(
    private router: Router,
    private alerts: AlertsService, private _snackBar: MatSnackBar,
    private http: HttpClient,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    // console.log(data);
  }

  public ngOnInit(): void {
    if (this.data != null) {
      let data = JSON.parse(JSON.stringify(this.data));
      this.addOrganizationForm = this.fb.group({
        name: [data.name, [Validators.required]],
        file: ['']
      });
    } else {
      this.addOrganizationForm = this.fb.group({
        name: [null, [Validators.required]],
        file: ['']
      });
    }
    this.breakpoint = window.innerWidth <= 600 ? 1 : 2; // Breakpoint observer code
    this.router.onSameUrlNavigation = 'reload';
  }

  public onAddCus(): void {
    this.markAsDirty(this.addOrganizationForm);
  }

  openDialog(): void {
    this.dialog.closeAll();
  }

  // tslint:disable-next-line:no-any
  public onResize(event: any): void {
    this.breakpoint = event.target.innerWidth <= 600 ? 1 : 2;
  }

  private markAsDirty(group: FormGroup): void {
    group.markAsDirty();
    // tslint:disable-next-line:forin
    for (const i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  addOrganization() {
    if (this.data != null) {
      let x = this.data;
      let y = this.addOrganizationForm.value;
      let editOrganization = { id: x.id, name: y.name };
      const formData = new FormData();
      formData.append('file', this.addOrganizationForm.get('file').value);
      formData.append('id', x.id);
      formData.append('organization', this.addOrganizationForm.value.name);
      // console.log(editOrganization)
      this.http.post(AppConstants.BASE_URL + AppConstants.UPDATE_ORGANIZATION, formData)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Organization Updated Successfully !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('Organization with Name already registered !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    } else {
      const formData = new FormData();
      formData.append('file', this.addOrganizationForm.get('file').value);
      formData.append('organization', this.addOrganizationForm.value.name);
      // console.log(formData.get('organization'))
      this.http.post(AppConstants.BASE_URL + AppConstants.CREATE_ORGANIZATION, formData)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this.dialog.closeAll();
          }
          else if (this.response.code == 201) {
            this._snackBar.open('Organization with name already registered !', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }

  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addOrganizationForm.get('file').setValue(file);
    }
  }

}